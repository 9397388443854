import i18next from 'i18next';


i18next.init({
    fallbackLng: 'en',
    resources: {
        pl: {
            translation: require('../locales/pl/translation.json')
        },
        en: {
            translation: require('../locales/en/translation.json')
        },
        ru: {
            translation: require('../locales/ru/translation.json')
        },
        es: {
            translation: require('../locales/es/translation.json')
        }
    },
    ns: ['translation'],
    defaultNS: 'translation',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        wait: true,
    },
});

i18next.languages = ['en', 'pl', 'ru', 'es'];
i18next.loadLanguages(['en', 'pl', 'ru', 'es'], (err) => { /* resources have been loaded */
});

export default i18next;