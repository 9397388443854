import React from "react"


//import ImgUp from "../img/UP.png"
//import ImgUpIt from "../img/SU.png"
import ImgUpP from "../img/sloiki/iko_gora.png"
//import ImgDown from "../img/DOWN.png"
//import ImgDownIt from "../img/GIU.png"
import ImgDownP from "../img/sloiki/iko_gora.png"

import CSSTopMenu from "../styles/suwak.css"


import i18next from '../i18n/config'


        const $ = require("jquery");



var nextToGoPos = 0;

export default class Suwak extends React.Component {

    constructor(props) {
        super(props);
//        this.state = {
//            nextToGoPos: 0
//        }













        if (typeof window !== `undefined`) {

            document.showSectionsOnScreen = function () {

                var bottom_of_window = $(window).scrollTop() + $(window).height();

//on auto scrool
                if (document.scrolltosectionPos > 0) {

                    if (bottom_of_window > document.scrolltosectionPos) {
                        $('section').each(function (i) {
                            var bottom_of_object = $(this).position().top;
                            $(this).removeClass("is-visible");
                            if (bottom_of_window > bottom_of_object) {
                                $(this).addClass("is-visible");
                            }
                        });
//                                console.log("transition1")

//                                $('select *').css({'transition': 'transition: opacity 0.4s ease-out, transform 0.6s'});
                        document.scrolltosectionPos = 0;

                    } else {
//                                $('select *').css({'transition': 'none'});
//                                console.log("transition000")

                    }
                } else {
                    $('section').each(function (i) {
                        var bottom_of_object = $(this).position().top;
                        $(this).removeClass("is-visible");
                        if (bottom_of_window > bottom_of_object) {
                            $(this).addClass("is-visible");
                        }
                    });
                }
            }

            document.fixSafariH1 = function () {

                if ($(window).scrollTop() == 0) {
                    $('html, body').animate({
                        scrollTop: 1
                    }, 1, function () {
                    });
                }
            }

            document.scrolltosection = function (id, loop = 0) {


                try {

                    document.scrolltosectionPos = ($("#" + id).offset().top);
                    document.scrolltosectionPos = document.scrolltosectionPos - 55;//top
//            if ($("#" + id).hasClass("is-visible")) {
//            } else {
//                pos = pos - 22;//menu
//            }

//                console.log(document.scrolltosectionPos);

                    let speed = document.scrolltosectionPos - $(window).scrollTop();

                    if (loop == 1) {
                        speed = 100;
                    }



                    $('html, body').animate({
                        scrollTop: document.scrolltosectionPos
                    }, speed / 2, function () {
                        if (loop == 0) {
                            document.scrolltosection(id, 1);
                        }

                    });
                } catch (error) {
            }

//                console.log("scrol to:", document.scrolltosectionPos);

//
//            var myTopnav = document.getElementById("myTopnav");
//
//            if (
//                    myTopnav.className != "topnav"
//                    &&
//                    "topheader" != id) {
//                this.menuClick();
//            }
            }




            setTimeout(function () {
                try {
                    let allElements = document.getElementsByTagName("section");
                    nextToGoPos = ($("#" + allElements[0].id).offset().top);
                    nextToGoPos = nextToGoPos - 200;//top
                    if ($("#" + allElements[0].id).hasClass("is-visible")) {
                    } else {
                        nextToGoPos = nextToGoPos - 11;//effect
                    }
                } catch (error) {
                }


            }, 20);

//        var nextToGoPos = 22;



            setInterval(function () {
                Suwak.showSuwak();
            }, 501);

            setInterval(function () {
                document.showSectionsOnScreen();
            }, 1501);



            setInterval(function () {
                document.fixSafariH1();
            }, 501);


        }





        if (typeof window !== `undefined`) {



            document.timer1Azachecanie1 = "";
            document.timer1Azachecanie2 = "";
            document.Azachecanie1InAnimate = false;

            document.addEventListener('keydown', function (e) {
                switch (e.keyCode) {
                    case 38:
                        Suwak.UpClick();
                        break;
                    case 40:
                        Suwak.DownClick();
                        break;
                }
            });
        }
//        setTimeout(function () {
//            Suwak.showSuwak();
//        }, 166);
//
//
//        if (typeof window !== `undefined`) {
//            $(window).on("scroll", function (e) {
//                Suwak.showSuwak();
//            });
//        }









        if (typeof window !== `undefined`)
            $(document).ready(function () {
//            $('section').animate({'opacity': '0'}, 11);

                /* Every time the window is scrolled ... */

                if (typeof window !== `undefined`)
                    $(window).scroll(function () {


                        document.showSectionsOnScreen();


                        if (document.Azachecanie1InAnimate == false) {
                            document.Azachecanie1InAnimate = true;

//   window.clearTimeout(document.timer1Azachecanie1);
////                                window.clearTimeout(document.timer1Azachecanie2);

                            document.timer1Azachecanie1 = window.setTimeout(function () {

                                $('a', $('.is-visible')).css({
                                    '-webkit-transform': 'scale(1.19)',
                                    '-moz-transform': 'scale(1.19)',
                                    '-ms-transform': 'scale(1.19)',
                                    '-o-transform': 'scale(1.19)',
                                    'transform': 'scale(1.19)'
                                });
                            }, 900);

                            document.timer1Azachecanie2 = window.setTimeout(function () {

                                $('a', $('.is-visible')).css({
                                    '-webkit-transform': 'scale(1)',
                                    '-moz-transform': 'scale(1)',
                                    '-ms-transform': 'scale(1)',
                                    '-o-transform': 'scale(1)',
                                    'transform': 'scale(1)'
                                });
                                document.Azachecanie1InAnimate = false;


                            }, 1200);
                        }




//                        $('.fadeInmw').each(function (i) {
//                            var bottom_of_object = $(this).position().top;
//                            var bottom_of_window = $(window).scrollTop() + $(window).height();
//                            $(this).removeClass("is-visible");
//                            if (bottom_of_window > bottom_of_object) {
//                                $(this).addClass("is-visible");
//                            }
//
//                        });




                    });

//            $('html, body').animate({
//                scrollTop: 0
//            }, 0, function () {
//            });

                //$("#suwakUp").hide(0);
//                $("#suwakUpP").hide(0);

//                $("#suwakDown").hide(0);
//                $("#suwakDownP").hide(0);
//
//                opacity0
//                $('#suwakDown').animate({'opacity': '0'}, 11);




                $(window).delay(666).scroll();



                var last_window_width = $(window).width();


                window.addEventListener('resize', function () {
                    if (Math.abs(last_window_width - $(window).width()) > 22) {
                        last_window_width = $(window).width();

                        $("html, body").stop();

                        $('html, body').animate({
                            scrollTop: (window.scrollY - 5)
                        }, 700, function () {
                            Suwak.showSuwak();
                        });
                    }
                });




                $(window).scroll(function () {


//                    $("#banerimg").css("marginTop", ($(window).scrollTop() - $(".baner").position().top));
//                    $(".baner").animate({
//                        scrollTop: ($(window).scrollTop() - $(".baner").position().top) + 300
//                    }, 1, function () {
//                        // if you need a callback function
//                    });



//                    var c = document.getElementById("banerc");
//                    var ctx = c.getContext("2d");
//                    var img = document.getElementById("banerimg");
//
//
//
//                    ctx.save();
//                    ctx.translate(-111, 0);
//                    ctx.drawImage(img, 10, ($(window).scrollTop() - $(".baner").position().top));
//
//                    ctx.restore();
//  <canvas id="banerc" />

                });












                $(document).ready(function () {
                    $(".lds-ring").delay(777).hide(444);
                });



//            $('html, body').delay(222).animate({
//                scrollTop: 0
//            }, 800, function () {
//                Suwak.showSuwak();
//            });



            });















//        document.addEventListener(
//    'scroll',
//    function(event){
//        var $elm = $(event.target);
//        if( $elm.is('div.dxgvHSDC + div')){ // or any other filtering condition
//            // do some stuff
//            console.log('scrolling');
//        }
//    },
//    true // Capture event
//);



//        this.setState(postmp => {
//            return {nextToGoPos: 1};
//        });
//        this.showSuwak = this.showSuwak.bind(this);

    }

    static showSuwak()
    {



//last?

        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent * 100);
        if (scrollPercentRounded > 94) {
            $("#suwakDown").addClass('opacity0');

            //$("#suwakDown").stop();
//            $("#suwakDown").hide(444);
//            $("#suwakDownP").hide(444);
        } else {
            $("#suwakDown").removeClass('opacity0');

            //  $("#suwakDown").stop();
//            $("#suwakDown").show(444);
//            $("#suwakDownP").show(444);
        }


        if (
                scrollPercentRounded > 8
                ||
                (
                        window.scrollY > 200
                        &&
                        document.body.clientWidth < 600
                        &&
                        document.body.clientHeight > 800
                        )
                ) {
            $("#suwakUp").removeClass('opacity0');
//            $("#suwakUp").stop();
//            $("#suwakUp").show(444);
//            $("#suwakUpP").show(444);
        } else {
            $("#suwakUp").addClass('opacity0');
//            $("#suwakUp").stop();
//            $("#suwakUp").hide(444);
//            $("#suwakUpP").hide(444);
        }





        // console.log(scrollPercentRounded);

        var activeMenuName;
        var allElements = document.getElementsByTagName("section");



        if (allElements.length < 3 && document.body.clientWidth > 600) {
            $("#suwakDown").addClass('opacity0');
            $("#suwakUp").addClass('opacity0');
            return 0;
        } else {


        }



//        console.log(1, nextToGoPos);




        if (allElements.length == 0)
            return 0;
//        try {
//#menu active


//            nextToGoPos = ($("#" + allElements[0].id).offset().top);
//            nextToGoPos = nextToGoPos - 57;//top
//            if ($("#" + allElements[0].id).hasClass("is-visible")) {
//            } else {
//                nextToGoPos = nextToGoPos - 11;//effect
//            }
//        } catch (error) {
//        }

//        this.setState({nextToGoPos: postmp});


        let pos;
//        $(".MenuTtem").removeClass("MenuActive");
//        $('.MenuTtem').css("color", "");
        if (window.scrollY > 200 || true) {
            for (var i = 0; i < allElements.length; ++i) {
//                console.log("for...", i);
                try {
                    pos = ($("#" + allElements[i].id).offset().top);
                } catch (error) {
                }

//                console.log(i, pos, window.scrollY + 200);

                if (pos > window.scrollY + 200) {
//                    this.setState({nextToGoPos: allElements[i].id.offset().top});
//                    nextToGoPos = allElements[i].id.offset().top;
//                    nextToGoPos = $("#" + allElements[i].id).offset().top - 272;

                    // let allElements = document.getElementsByTagName("section");
                    try {
                        nextToGoPos = ($("#" + allElements[i].id).offset().top);
                        nextToGoPos = nextToGoPos - 44; //top
//                        if ($("#" + allElements[i].id).hasClass("is-visible")) {
//                        } else {
//                            nextToGoPos = nextToGoPos - 11; //effect
//                        }
                    } catch (error) {
                    }


                    return 1;
                    break;
                }
                activeMenuName = "#Menu_" + allElements[i].id;
            }
            var forMenuName;
//            for (var i = 0; i < allElements.length; ++i) {
//                forMenuName = "#Menu_" + allElements[i].id;
//
//
//                if (forMenuName == activeMenuName) {
////                    console.log(forMenuName, activeMenuName);
//
//                    $(forMenuName).css("color", "black");
//
//                } else {
//                    $(forMenuName).css("color", "");
//                }
//
//
//
//            }





        } else {


            try {

                $('.MenuTtem').css("color", "");
//            this.setState({nextToGoPos: $("#" + allElements[0].id).offset().top});
                let allElements = document.getElementsByTagName("section");
                nextToGoPos = ($("#" + allElements[0].id).offset().top);
                nextToGoPos = nextToGoPos - 57; //top
                if ($("#" + allElements[0].id).hasClass("is-visible")) {
                } else {
                    nextToGoPos = nextToGoPos - 11; //effect
                }
            } catch (error) {
            }
        }




















//        var canvas = document.getElementById('banerimg');
//        var context = canvas.getContext('2d');
//        var x = (($(window).scrollTop() - $(".baner").position().top) - canvas.width);

//        var y = (($(window).scrollTop() - $(".baner").position().top) - canvas.height);

//
//
//        var img = new Image();
//        img.src = canvas.src;
//
//        console.log(y);
//        context.drawImage(img, x, y);
//









//        var to = $(window).scrollTop() - $(".baner").position().top
//        console.log(to);
////        $('.banerMobile').scrollTop(to);
//
//
//
//        $('.banerMobile').stop(true, true).animate({
//            scrollTop: to
//        }, 333);





//        $('.baner').animate({
//            background - position - y: ($(window).scrollTop() - $(".baner").position().top);
//        }, 666, function () {
//        });

//        $('.baner').css('background-position-y', ($(window).scrollTop() - $(".baner").position().top) + 'px');
    }

    static UpClick()
    {


        $("html, body").stop();

        $('html, body').animate({
            scrollTop: 0
        }, 700, function () {
            Suwak.showSuwak();
        });


//        document.location.href = "#top";
    }
    static DownClick()
    {
//        let allElements = document.getElementsByTagName("section");
//        let pos;
//        var i = 0;
//        for (i = 0; i < allElements.length; ++i) {
//            pos = ($("#" + allElements[i].id).offset().top);
//            if (pos - 111 > window.scrollY) {
//                break;
//            }
//
//        }
////        pos = pos - 215;
////        alert(i);
//        if (i == 0) {
//            pos = pos - 190;
//
//        } else {
//            pos = pos - 215;
//
//        }
        $("html, body").stop();

        $('html, body').animate({
            scrollTop: nextToGoPos
        }, 700, function () {
            Suwak.showSuwak();
        });
    }

    render() {


        return (
                <div id="suwak" className="suwak">
                    <img src={ImgUpP} href="javascript:void(0);" id="suwakUp" className="icon opacity0" onClick={Suwak.UpClick} alt="UP" />
                    <br />
                    <img src={ImgDownP} href="javascript:void(0);" id="suwakDown" className="icon opacity0" onClick={Suwak.DownClick} alt="Down" />
                </div>

                )
    }

}
