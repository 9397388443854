import React from "react"
import ReactDOM from "react-dom";
import {
//BrowserRouter as
//Router,
//        Switch,
useLocation
} from "react-router-dom";
import i18next from '../i18n/config';
import * as path from 'path';
import {withRouter} from 'react-router-dom';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import '../styles/menuDown.sass';
import '../styles/menu.scss';
import '../styles/menuDown.sass';
// import ImgLogo from "../img/A_glowna_1/instanta_logo_male.png";

import ImgUE from "../img/icon/projekty_pl.png";
import ImgUEMenu from "../img/icon/menu_icon_dofinansowania.png";
import ImgLogo from "../img/A_glowna_1/instanta_logo_gold.svg";
// import ImgLogo from "../img/A_glowna_1/logo_i2.png";
// import ImgLogo from "../img/icon/i_kolo_gold.png";
// import ImgLogo from "../img/icon/i_kolo_black.png";
// import ImgLogo from "../img/icon/instanta_logo_i2.png";
// import ImgLogo from "../img/A_glowna_1/instanta_logo_2.png";
// import ImgLogo from "../img/logo/instanta_logo_180x59px.png";


import ImgEn from "../img/A_glowna_1/EN.png";
import ImgEs from "../img/A_glowna_1/ES.png";
import ImgPl from "../img/A_glowna_1/PL.png";
import ImgRu from "../img/A_glowna_1/RU.png";
//export default function Menu(props)

import logoMiniMobile from "../img/menu/instanta_logo_gold.png";
        const $ = require("jquery");
//const SomeComponent = withRouter(props => <Menu {...props}/>);



export default class Menu extends React.Component {

constructor(props) {

super(props);
//        let value = this.context;
//        console.log(this);
//        console.log(props);


//        console.log("sss", $sss);


//        console.log(props);
        if (typeof this.state == `undefined`)
        this.state = {
        mySelectFlagsrc: ImgEn,
                urlHome: "/",
                SelectLanguageByUser: ''

        };
//        console.log(this.state.SelectLanguageByUser);

        if (typeof document !== `undefined`)
        setTimeout(function () {




        document.getElementById('wrapper3').addEventListener("mouseover", function () {
        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)

                )
                return true;
                document.getElementById('wrapper3').className = document.getElementById('wrapper3').className + '  active';
        });
                document.getElementById('wrapper3').addEventListener("mouseout", function () {

        if (
                $(window).width() < 1000
                )
                return true;
                let newclass = document.getElementById('wrapper3').className.replace('active', ' ');
                newclass.replace('active', ' ');
                document.getElementById('wrapper3').className = newclass;
        });
                document.getElementById('wrapper3').onclick = function () {

        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)
                ) {
        } else
                return true;
//                    $("#wrapper3").removeClass("active");
                $("#wrapper4").removeClass("active");
                $("#wrapper5").removeClass("active");
                $("#wrapper6").removeClass("active");
//                    const menuToggle = document.querySelector('.nav__toggle');
//                    menuToggle.click();
                var className = ' ' + document.getElementById('wrapper3').className + '  ';
                this.className = ~className.indexOf('active') ?
                className.replace('active', ' ') :
                this.className + ' active';
        }



///////////////////////////////

        document.getElementById('wrapper4').addEventListener("mouseover", function () {
        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)

                )
                return true;
                document.getElementById('wrapper4').className = document.getElementById('wrapper4').className + ' active';
        });
                document.getElementById('wrapper4').addEventListener("mouseout", function () {
        if (
                $(window).width() < 1000
                )
                return true;
                let newclass = document.getElementById('wrapper4').className.replace('active', ' ');
                newclass.replace('active', ' ');
                document.getElementById('wrapper4').className = newclass;
        });
                document.getElementById('wrapper4').onclick = function () {
        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)
                ) {
        } else
                return true;
                $("#wrapper3").removeClass("active");
//                    $("#wrapper4").removeClass("active");
                $("#wrapper5").removeClass("active");
                $("#wrapper6").removeClass("active"); //                    const menuToggle = document.querySelector('.nav__toggle');
//                    menuToggle.click();
                var className = ' ' + document.getElementById('wrapper4').className + '  ';
                this.className = ~className.indexOf('active') ?
                className.replace('active', ' ') :
                this.className + ' active';
        }




///////////////////////////////
//                document.getElementById('wrapper5').className = document.getElementById('wrapper5').className.replace('active', ' ');
//                $("#wrapper5").removeClass("active");
//
//                setTimeout(function () {
//                    $("#wrapper5").removeClass("active");
//                }, 5);


        document.getElementById('wrapper5').addEventListener("mouseover", function () {
        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)

                )
                return true;
                document.getElementById('wrapper5').className = document.getElementById('wrapper5').className + '  active mouseover';
        });
                document.getElementById('wrapper5').addEventListener("mouseout", function () {
        if (
                $(window).width() < 1000
                )
                return true;
                let newclass = document.getElementById('wrapper5').className.replace('active', ' ');
                newclass.replace('active', ' ');
                document.getElementById('wrapper5').className = newclass;
        });
                document.getElementById('wrapper5').onclick = function () {
        if (
                $(window).width() < 1000
                ||
                (window.matchMedia("(pointer: coarse)").matches)
                ) {
        } else
                return true;
                $("#wrapper3").removeClass("active");
                $("#wrapper4").removeClass("active");
                $("#wrapper5").removeClass("active");
                $("#wrapper6").removeClass("active");
//                    const menuToggle = document.querySelector('.nav__toggle');
//                    menuToggle.click();
                //var className = ' ' + document.getElementById('wrapper4').className + '  ';
                document.getElementById('wrapper5').className = document.getElementById('wrapper5').className;
                //console.log(document.getElementById('wrapper5').className, $("#wrapper5").hasClass('active'));
                if ($("#wrapper5").hasClass('active')) {
        document.getElementById('wrapper5').className = document.getElementById('wrapper5').className.replace('active', ' ');
        } else {
        document.getElementById('wrapper5').className = document.getElementById('wrapper5').className + '  active ';
        }
        }

//        document.getElementById('wrapper6').addEventListener("mouseover", function () {
//        if (
//                $(window).width() < 1000
//                ||
//                (window.matchMedia("(pointer: coarse)").matches)
//
//                )
//                return true;
//                document.getElementById('wrapper6').className = document.getElementById('wrapper6').className + '  active mouseover';
//        });
//                document.getElementById('wrapper6').addEventListener("mouseout", function () {
//        if (
//                $(window).width() < 1000
//                )
//                return true;
//                let newclass = document.getElementById('wrapper6').className.replace('active', ' ');
//                newclass.replace('active', ' ');
//                document.getElementById('wrapper6').className = newclass;
//        });
//                document.getElementById('wrapper6').onclick = function () {
//        if (
//                $(window).width() < 1000
//                ||
//                (window.matchMedia("(pointer: coarse)").matches)
//                ) {
//        } else
//                return true;
//                $("#wrapper3").removeClass("active");
//                $("#wrapper4").removeClass("active");
//                $("#wrapper5").removeClass("active");
//
//                document.getElementById('wrapper6').className = document.getElementById('wrapper6').className;
//                 if ($("#wrapper6").hasClass('active')) {
//        document.getElementById('wrapper6').className = document.getElementById('wrapper6').className.replace('active', ' ');
//        } else {
//        document.getElementById('wrapper6').className = document.getElementById('wrapper6').className + '  active ';
//        }
//        }




console.log("Current language:", i18next.language);



        const nav = document.querySelector('#nav');
                const menu = document.querySelector('#menu');
                const menuToggle = document.querySelector('.nav__toggle');
// TOGGLE MENU ACTIVE STATE
                menuToggle.addEventListener('click', e => {
//                    console.log(1);
                $("#wrapper4").removeClass("active");
                        $("#wrapper6").removeClass("active");
                        $("#wrapper5").removeClass("active");
                        e.preventDefault();
                        let isMenuOpen = false;
                        isMenuOpen = menuToggle.getAttribute('aria-expanded');
                        isMenuOpen = !isMenuOpen;
                        //console.log(isMenuOpen);
                        // toggle a11y attributes andactiveclass
                        menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
                        menu.hidden = !isMenuOpen;
                        nav.classList.toggle('nav--open');
                });
// TRAP TAB INSIDE NAV WHEN OPEN
                nav.addEventListener('keydown', e => {
                // abort if menu isn't open or modifier keys are pressed
//                if (!isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
//                    return;
//                }

                // listen for tab press and move focus
                // if we're on either end of the navigation
                const menuLinks = menu.querySelectorAll('.nav__link');
                        if (e.keyCode === 9) {
                if (e.shiftKey) {
                if (document.activeElement === menuLinks[0]) {
                menuToggle.focus();
                        e.preventDefault();
                }
                } else if (document.activeElement === menuToggle) {
                menuLinks[0].focus();
                        e.preventDefault();
                }
                }
                });
        }, 30);
//location

//        this.linkClick = this.linkClick.bind(this);


        }

//    changeLanguageToPlClick()
//    {
//        console.log(222);
//        i18next.changeLanguage('pl', (err, t) => {
//        }
//        );
//
//        setTimeout(function () {
//            document.location.href = "#1";
//        }, 33);
//        setTimeout(function () {
//            document.location.href = "#top";
//        }, 66);
//
//    }





linkClick() {
//        console.log(this);
//        document.location.href = "http://localhost:8000/OurPackaging/Packaging/"


}

linkEndClick() {
//        console.log(this);
//        document.location.href = "http://localhost:8000/OurPackaging/Packaging/"

$("#nav").removeClass("nav--open");
        }

togleMenuDown() {
//                    const menuToggle = document.querySelector('.nav__toggle');
//                    menuToggle.click();
if (typeof document !== `undefined`)
        var className = ' ' + document.getElementById('wrapper3').className + '  ';
        this.className = ~className.indexOf('active') ?
        className.replace('active', ' ') :
        this.className + ' active';
        }

render() {
let urltogoPL = ""
        let urltogoEN = ""
        let urltogoES = ""
        let locationTmp = "";
        var mySelectFlagsrc



        if (typeof document !== `undefined`) {




locationTmp = window.location.pathname + window.location.search;
        let first3 = locationTmp.substr(0, 3);
        if (
                first3 == "/pl"
                ||
                first3 == "/es"
                ) {
locationTmp = window.location.pathname.substr(3);
        }
//
//
urltogoPL = locationTmp
        urltogoEN = locationTmp
        urltogoES = locationTmp
//
//
//            var urlHome;

//
//            mySelectFlagsrc = ImgEn
//            if (nowSelectLang == "es")
//                mySelectFlagsrc = ImgEs;
//            if (nowSelectLang == "pl")
//                mySelectFlagsrc = ImgPl;


//            console.log(nowSelectLang);



//            setTimeout(function () {
//
//
//
//            mySelectFlagsrc = ImgEn
//            if (i18next.logger.options.lng == "es")
//                mySelectFlagsrc = ImgEs;
//            if (i18next.logger.options.lng == "pl")
//                mySelectFlagsrc = ImgPl;
//                console.log(nowSelectLang, "tine");
//
//                document.getElementById("mySelectFlag").src = mySelectFlagsrc;
//
//
//
//            }, 30);

//            console.log("render");
        if (this.state.mySelectFlagsrc != ImgEs
                &&
                window.location.pathname.substr(0, 3) == "/es") {
this.setState({
mySelectFlagsrc: ImgEs,
        urlHome: "/es"
        });
        }



if (
        this.state.mySelectFlagsrc != ImgPl
        &&
        window.location.pathname.substr(0, 3) == "/pl"
        ) {
//                console.log("set mySelectFlagsrc pl ok in render");

this.setState({
mySelectFlagsrc: ImgPl,
        urlHome: "/pl"
        });
        }


//            if (
//                    window.location.pathname.substr(0, 3) == "/es") {
//
//                urlHome = "/es"
//
//            }
//
//
//
//            if (
//                    window.location.pathname.substr(0, 3) == "/pl"
//                    ) {
//                urlHome = "/pl"
//
//
//
//
//            }
//


function setCookie2(cname, cvalue, exdays) {
var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
function getCookie(name) {
var nameEQ = name + "=";
        if (typeof window !== `undefined`) {
var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
var c = ca[i];
        while (c.charAt(0) == ' ')
        c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
        return c.substring(nameEQ.length, c.length);
        }
}
return null;
        }


let SelectLanguageByUser = getCookie("SelectLanguageByUser");
        if (SelectLanguageByUser == null) {

let lang = navigator.language.split('-')[0];
        if (lang == "pl") {
setCookie2("SelectLanguageByUser", 'pl', 333);
        document.location = urltogoPL + document.location.search;
        ;
//            i18next.changeLanguage('pl', (err, t) => {
//            });
        console.log('changeLanguage to pl by browser def lang ');
        } else if (lang == "en") {
setCookie2("SelectLanguageByUser", 'en', 333);
        document.location = urltogoEN + document.location.search;
        ;
//            i18next.changeLanguage('en', (err, t) => {
//            });
        console.log('changeLanguage to en by browser def lang ');
        } else if (lang == "es") {
//            i18next.changeLanguage('es', (err, t) => {
//            });
//                    console.log('changeLanguage to es by browser def lang ');
setCookie2("SelectLanguageByUser", 'es', 333);
        document.location = urltogoES + document.location.search;
        ;
        }
}





}

//        locationTmp = locationTmp.replace("//", "/")


return (
        <React.Fragment>

    <nav id="nav" class="nav menu " role="navigation">
        <div class="logo">
            <Link
                to="/"
                activeStyle={{color: "#f7c580"}}
                partiallyActive={true}
                >
            <img src={ImgLogo} alt="Instanta Logo" />
            </Link>
        </div>






        <ul class="nav__menu" id="menu" tabindex="-1" aria-label="main navigation" hidden>

            <li class="nav__item onlyMobile logoMiniMobileLi" onClick={this.linkEndClick} >
                <Link
                    to="/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                <img id="logoMiniMobile" src={logoMiniMobile} alt="Instanta Logo" />
                </Link>
                <div class="menuSepTop"></div>
            </li>






            <li class="nav__item" onClick={this.linkEndClick}>
                <Link
                    to="/AboutUs/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                {i18next.t('Menu1')}
                </Link>
            </li>

            {
                        //    <li class="nav__item rozwijalne" onClick={this.linkEndClick}>
                        //                <div class="wrapperMenu wrapper3"  id="wrapper6">
                        //            <a class="click-text nav__link textToRightifMobile">
                        //                {i18next.t('Menu1')} <span class="arrow"></span>
                        //                  </a>
                        //                  <ul class="wrapperMenuUl fix">
                        //                      <li class="nav__item" onClick={this.linkEndClick}>
                        //                          <Link
                        //                              to="/AboutUs"
                        //                              className={('nav__link')}
                        //                              activeStyle={{color: "#f7c580"}}
                        //                              partiallyActive={true}
                        //                              >
                        //                          {i18next.t('Menu1')}
                        //                          <div class="menuSepTopPC EmpZ"></div>

                        //                          </Link>
                        //                      </li>
                        //                      <li   class="nav__item" onClick={this.linkEndClick} >
                        //                          <Link
                        //                              to="/AboutUs/OurPlants"
                        //                              className={('nav__link')}
                        //                              activeStyle={{color: "#f7c580"}}
                        //                              partiallyActive={true}
                        //                              >
                        //                          {i18next.t('Our plants')}
                        //                          </Link>
                        //                      </li>
                        //                  </ul>
                        //              </div>
                        //    </li>
            }












            <li class="nav__item" onClick={this.linkEndClick}>
                <Link
                    to="/OurCoffees/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                {i18next.t('Menu2')}
                </Link>
            </li>
            <li class="nav__item rozwijalne">


                <div class="wrapperMenu wrapper3"  id="wrapper3">
                    <a class="click-text nav__link">
                        {i18next.t('Menu3')} <span class="arrow"></span>
                    </a>
                    <ul class="wrapperMenuUl fix">
                        <li class="nav__item" onClick={this.linkEndClick}>
                            <Link
                                to="/OurPackaging/Packaging"
                                className={('nav__link')}
                                activeStyle={{color: "#f7c580"}}
                                partiallyActive={true}
                                >
                            {i18next.t('Packaging')}
                            </Link>
                            <div class="menuSepTopPC"></div>

                        </li>

                        <li class="nav__item" onClick={this.linkEndClick}>
                            <Link
                                to="/OurPackaging/Design"
                                className={('nav__link')}
                                activeStyle={{color: "#f7c580"}}
                                partiallyActive={true}
                                >
                            {i18next.t('Design')}
                            </Link>
                        </li>
                    </ul>
                </div>




            </li>
            <li class="nav__item" onClick={this.linkEndClick}>
                <Link
                    to="/Collaboration/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                {i18next.t('Menu4')}
                </Link>
            </li>
            <li class="nav__item" onClick={this.linkEndClick}>
                <Link
                    to="/Quality/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                {i18next.t('Menu5')}
                </Link>
            </li>
            <li class="nav__item rozwijalne" >



                <div class="wrapperMenu wrapper3"  id="wrapper4">
                    <a class="click-text nav__link">
                        {i18next.t('ForEmployees')} <span class="arrow"></span>
                    </a>
                    <ul class="wrapperMenuUl fix">
                        <li class="nav__item" onClick={this.linkEndClick}>
                            <Link
                                to="/ForEmployees/EmployeeZone"
                                className={('nav__link')}
                                activeStyle={{color: "#f7c580"}}
                                partiallyActive={true}
                                >
                            {i18next.t('EmployeeZone')}
                            <div class="menuSepTopPC EmpZ"></div>

                            </Link>
                        </li>
                        <li class="nav__item" onClick={this.linkEndClick} >
                            <Link
                                to="/ForEmployees/Career"
                                className={('nav__link')}
                                activeStyle={{color: "#f7c580"}}
                                partiallyActive={true}
                                >
                            {i18next.t('Career')}
                            </Link>
                        </li>
                    </ul>
                </div>



            </li>






            <li class="nav__item" onClick={this.linkEndClick} >
                <Link
                    to="/Contact/"
                    className={('nav__link')}
                    activeStyle={{color: "#f7c580"}}
                    partiallyActive={true}
                    >
                {i18next.t('Menu7')}
                </Link>
            </li>

        </ul>

        <a href="#nav" class="nav__toggle" role="button" aria-expanded="false" aria-controls="menu">
            <svg class="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" >
            <title>Toggle Menu</title>
            <g>
            <line class="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
            <circle class="menuicon__circle" r="23" cx="25" cy="25" />
            </g>
            </svg>
        </a>

        <div class="splash"></div>


        <div class="flags">


            <div class="wrapperMenu wrapper3"  id="wrapper5">
                <a class="click-text nav__link">
                    <img id="mySelectFlag" src={this.state.mySelectFlagsrc} alt="Select flag" />
                    <span class="arrow"></span>
                </a>
                <ul class="wrapperMenuUl">
                    <li class="nav__item">
                        <Link
                            onClick={e => {
//                                                    i18next.changeLanguage('en', (err, t) => {
//                                                    });
//                                                    this.setState({
//                                                        mySelectFlagsrc: ImgEn,
//                                                        urlHome: "/en"
//                                                    });
if (typeof document !== `undefined`) {
document.SelectLanguageByUser = 'en';
//                                                        document. = "pl";

        document.getElementById("mySelectFlag").src = ImgEn;
        }
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#1";
//                                                    }, 33);
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#top";
//                                                    }, 66);
//
//                                                    console.log("en");

//                                                        $("#mySelectFlag").src = ImgEn;
}}
                            to={urltogoEN}
                            language={'en'}
                            className={('nav__link')}
                            activeStyle={{color: "#f7c580"}}
                            partiallyActive={true}
                            >
                        <img src={ImgEn} alt="en flag" />

                        </Link>
                        <Link
                            onClick={e => {
//                                                    if (typeof document !== `undefined`)
//                                                        document.getElementById("mySelectFlag").src = ImgPl;
//                                                    console.log("pl");
//                                                    i18next.changeLanguage('en', (err, t) => {
//                                                    });
//                                                    this.setState({
//                                                        mySelectFlagsrc: ImgPl,
//                                                        urlHome: "/pl"
//                                                    });
if (typeof document !== `undefined`) {

document.SelectLanguageByUser = 'pl';
//                                                        document.SelectLanguageByUser = "pl";
        document.getElementById("mySelectFlag").src = ImgPl;
        }
//                                                    console.log("pl");
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#1";
//                                                    }, 33);
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#top";
//                                                    }, 66);

}}
                            to={urltogoPL}
                            language={'pl'}
                            className={('nav__link')}
                            activeStyle={{color: "#f7c580"}}
                            partiallyActive={true}
                            >
                        <img src={ImgPl} alt="pl flag" />

                        </Link>



                        <Link
                            onClick={e => {

//                <a href="http://www.instanta.su/ru/index.html">
//<img src={ImgRu} alt="ru flag" />
//  </a>

//                                                    i18next.changeLanguage('en', (err, t) => {
//                                                    });
//                                                    this.setState({
//                                                        mySelectFlagsrc: ImgEs,
//                                                        urlHome: "/es"
//                                                    });
if (typeof document !== `undefined`) {
//                                                        document.SelectLanguageByUser = "es";

document.SelectLanguageByUser = 'es';
        document.getElementById("mySelectFlag").src = ImgEs;
        }
//                                                    if (typeof document !== `undefined`)
//                                                        document.getElementById("mySelectFlag").src = ImgEs;
//                                                        $("#mySelectFlag").src = ImgEs;
//                                                    console.log("es");
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#1";
//                                                    }, 33);
//                                                    setTimeout(function () {
//                                                        document.location.href = document.location.href + "#top";
//                                                    }, 66);

}}
                            to={urltogoES}
                            language={'es'}
                            className={('nav__link')}
                            activeStyle={{color: "#f7c580"}}
                            partiallyActive={true}
                            >
                        <img src={ImgEs} alt="" />
                        </Link>
                    </li>
                </ul>
            </div>



        </div>




    </nav>













</React.Fragment>

        )

        }
}
